<script>
import {Carousel, Slide} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";
import vueSeamlessScroll from 'vue-seamless-scroll'

import Navbar from "@/components/navbar";
import Navbar2 from "@/components/navbar2";
// import Navbar4 from "@/components/navbar4";
import HomeBanner from "@/components/homeBanner";
import ContactInfo from "@/components/contactInfo";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import homeApi from "@/apis/homeApi";
import indexApi from "@/apis/indexApi";
import planApi from "@/apis/planApi";
import storage from '../storage';
import Features from "@/components/features";
import Transactions from '@/components/transactions'
import OurPartners from "@/components/ourPartners";
// import Plans from "@/components/plans";
import Testimonial from "@/components/testimonial1";
import date from '../date';
// import Plans from "@/components/plans1";
import Plans from "@/components/plans";
import FooterBar from "@/components/footerBar";


import BTBSVG from "@/assets/icon/btc.svg"
import ETHSVG from "@/assets/icon/eth.svg"
import LTCSVG from "@/assets/icon/ltc.svg"
import USDTSVG from "@/assets/icon/usdt.svg"

/**
 * Index-1
 */
export default {
  data() {
    return {
      sharkFlag: false,
      promotionsShow:false,
      url: "",
      imageLoad: true,
      wallet: undefined,
      dashBoard: undefined,
      plans: undefined,
      plansLoad: 0,
      login: false,
      planForm: {
        pageNum: 1,
        pageSize: 10
      },
      alert: {
        content: "",
        presignedUrl: null,
        ifAlert: false,
        type: 2
      },
      options: {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      },
      huadon: {
        lastDepositList: undefined,
        lastWithdrawList: undefined
      },
      imagesPranters: [
        "google",
        "forbes",
        "yahoo",
        "youtube",
        "BINANCE",
        "coinbase",
        "coinpedia",
        "AMBCRYPTO",
        "BENZINGA",
        "CoinGape",
        "GlobeNewswire",
        "cryptionews",
        "AnalyticsInsight",
        "SOURECFORGE",
        "MarketWatch",
      ],
      payments: [
        BTBSVG,
        ETHSVG,
        LTCSVG,
        USDTSVG
        // "/images/crypto/bitcoin.png",
        // "/images/crypto/bitcoin-cash.png",
        // "/images/crypto/litecoin.png",
        // "/images/crypto/ethereum.png",
        //
        // "",
        // "/images/visaicon.jpg",
      ],
      featuresData: [
        {
          icon: "uil uil-rocket",
          title: "Advance Hardware",
          description:
              "We are using the latest hardware to ensure a high speed mining experience in a cost-effective manner.",
        },
        {
          icon: "uil uil-rss",
          title: "99.97%",
          description:
              "Uptime",
        },
        {
          icon: "uil uil-bitcoin-circle",
          title: "Instant Withdrawal",
          description:
              "You can withdraw your mined bitcoin instantly once you reach the minimum payment threshold, without any delays or freezing!",
        },
        {
          icon: "uil uil-head-side-cough",
          title: "Customer support 24/7",
          description:
              "Dedicated and professional customer support services available round-the-clock.",
        },
        {
          icon: "uil uil-bolt",
          title: "",
          description:
              "Mining starts immediately after payment",
        },
        {
          icon: "uil uil-wallet",
          title: "",
          description:
              "No hidden fees or commission. Everything is visible to you.",
        },
        {
          icon: "uil uil-bitcoin-alt",
          title: "",
          description:
              "Supports USDT, BTC, ETH, and LTC wallet address withdrawal",
        },
        {
          icon: "uil uil-processor",
          title: "",
          description:
              "No need to buy equipment",
        }
      ],
      testimonialData: [],
      bottom: "",
      comts: {
        pageNum: 1,
        pageSize: 6
      }
    }
  },
  components: {
    Navbar,
    // Navbar4,
    Switcher,
    HomeBanner,
    ContactInfo,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Plans,
    Features,
    Testimonial,
    OurPartners,
    Navbar2,
    vueSeamlessScroll,
    Transactions,
    FooterBar
  },
  created() {
    homeApi.notification(res => {
      if(res.data.ifAlert==true){
        this.promotionsShow = true
      }
      this.alert = res.data
    })
    indexApi.GetComments(
        this.comts,
        res => {
          let i = 0
          this.testimonialData.length = 0
          res.data.result.forEach(p => {
            i++;
            this.testimonialData.push({
              id: p.id,
              profile: "images/client/0" + i + ".jpg",
              message:
              p.content,
              name: p.email,
              designation: "",
              rate: p.ratings,
              title: p.title
            })
          })
        }
    )
    //获取Banner图片
    // homeApi.banner(res => {
    //   this.url = res.data.data
    // })
    indexApi.liushui(res => {
      this.huadon = res.data
    })
    //
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
      //获取仪表盘信息
      indexApi.dashBoard(res => {
        if (res.code != 200) {
          storage.localDel("userId")
          this.login = false
        } else {
          this.dashBoard = res.data
        }
      })
    }
    //未登录时显示100条数据
    if (!this.login) {
      this.planForm.pageSize = 12
    }
    //获取项目列表
    planApi.list(this.planForm, res => {
      this.plans = res.data
    })
  },
  mounted() {
    this.observeDOM()
    if(this.$route.path === '/index-3'){
      history.replaceState({}, "", "/");
    }
    setTimeout(()=>{
      this.sharkFlag = true;
    },2000)
    // const sharkDiv = document.getElementById("promotions-popup");
    // sharkDiv.addEventListener("animationend", this.stopShark);
  },
  methods: {
    observeDOM() {
      const observer = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.addedNodes) {
            for (const node of mutation.addedNodes) {
              if (node.tagName === "JDIV") {
                node.style.display = "none";
              }
            }
          }
        }
      });
      const config = { childList: true, subtree: true };
      const targetNode = document.documentElement; // 或者指定包含 JivoSite 的父元素
      observer.observe(targetNode, config);
    },
    getDate(d) {
      return date.getDate(d)
    },
    loadmore() {
      this.planForm.pageNum++
      //开始加载
      this.plansLoad = 1
      let pages = Math.ceil(this.plans.totalCount / this.planForm.pageSize)
      //获取项目列表
      if (pages >= this.planForm.pageNum) {
        planApi.list(this.planForm, res => {
          res.data.result.unshift(...this.plans.result)
          Object.assign(this.plans, res.data)
          this.plansLoad = 0
          console.log(pages)
          console.log(this.planForm.pageNum)
          if (pages <= this.planForm.pageNum) this.plansLoad = -1//结束加载
        })
      } else {
        this.plansLoad = -1;
      }
    },
    handleClick() {
      this.promotionsShow = false
    }
  },
};
</script>

<template>
  <div>
    <navbar></navbar>
    <!--    <Navbar v-if="!login" />-->
    <!--    <Navbar2 v-else />-->

    <!-- 主页Banner -->
    <!--    <section  class="bg-half-170 d-table w-100" style="height: 100vh;" id="home">-->
    <!--      <div class="container">-->
    <!--        <div class="row mt-5 align-items-center">-->
    <!--          <div class="col-lg-7 col-md-7">-->
    <!--            <div class="title-heading">-->

    <!--              <h1 class="heading mb-3">-->
    <!--                Start Cloud<br />-->
    <!--&lt;!&ndash;                <span class="text-primary">Mining Now! </span>&ndash;&gt;-->
    <!--                Mining Now!-->
    <!--              </h1>-->
    <!--              <p class="para-desc">-->
    <!--                StavlinShop is one of the leading cryptocurrency mining platforms, offering cryptocurrency mining capacities in every range - for newcomers. Our mission is to make acquiring cryptocurrencies easy and fast for everyone.-->
    <!--              </p>-->
    <!--              <div class="mt-4">-->
    <!--                <router-link to="/auth-login" class="btn btn-primary mt-2 mr-2">Get Started</router-link>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          &lt;!&ndash;end col&ndash;&gt;-->
    <!--          <div v-loading="imageLoad" element-loading-background="rgba(0, 0, 0, 0)"-->
    <!--            style="position: absolute;top: 0;left: 0;z-index: -1;height: 100%;width: 100%;overflow: hidden;">-->
    <!--            <el-image :src="url" v-show="!imageLoad" @load="imageLoad = false;" style="height: 100%;width: 100%;"-->
    <!--              fit="cover" width="100%" height="100%" alt="" />-->
    <!--          </div>-->
    <!--          &lt;!&ndash;end col&ndash;&gt;-->
    <!--        </div>-->
    <!--        &lt;!&ndash;end row&ndash;&gt;-->
    <!--      </div>-->
    <!--      &lt;!&ndash;end container&ndash;&gt;-->
    <!--    </section>-->

    <home-banner></home-banner>

    <div @click="handleClick">
      <div id="promotions-popup" v-show="promotionsShow"  v-if="alert.ifAlert"  class="modal fade show"  style="display: block;" tabindex="-1" aria-modal="true" role="dialog">
        <div v-if="alert.type==1" class="modal-dialog  modal-lg modal-dialog-centered">
          <img :class="[sharkFlag ?'my-shark' :'']" :src="alert.presignedUrl" alt=""/>
<!--          <img :class="[sharkFlag ?'my-shark' :'']" src="https://img2.baidu.com/it/u=1858905022,1506257963&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500" alt="">-->
        </div>
<!--     rgba(247,250,255, 1)   -->
        <div v-if="alert.type==2" class="modal-dialog modal-lg"  style="background-color: #2f55d4;border-radius: 15px;padding:15px;">
          <img style="height:30px;position:absolute;top: 5px;right: 5px;" src="images/app/x-circle.svg" alt="">
          <br/>
          <p style="font-family: Verdana ;color:White;font-size: 18px;" v-html="alert.content"></p>
        </div>
      </div>
    </div>


    <contact-info></contact-info>
    <!-- 未登录主页 -->
    <!--    <section class="section pb-2">-->
    <!--      <div class="container">-->
    <!--        <div class="row justify-content-center">-->
    <!--          <div class="col-12 text-center">-->
    <!--            <div class="section-title mb-4 pb-2">-->
    <!--              <h4 class="title mb-4">Cloud Mining Process</h4>-->
    <!--              <p class="text-muted para-desc mx-auto mb-0">-->
    <!--                How Does Cloud-->
    <!--                Mining Works?-->
    <!--              </p>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          &lt;!&ndash;end col&ndash;&gt;-->
    <!--        </div>-->
    <!--        &lt;!&ndash;end row&ndash;&gt;-->

    <!--        &lt;!&ndash; 一些步骤 &ndash;&gt;-->
    <!--        <el-row type="flex" class="flex-wrap" :gutter="20">-->
    <!--          <el-col :span="6" :xs="24" class="mt-4">-->
    <!--            <div class="card work-process border-0 rounded shadow">-->
    <!--              <div class="card-body">-->
    <!--                <h4 class="title">Sign up</h4>-->
    <!--&lt;!&ndash;                <p class="text-muted para">&ndash;&gt;-->
    <!--                <p>-->
    <!--                  Register your account in seconds! We offer a $10 bonus for new users.-->
    <!--                </p>-->
    <!--                <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">-->
    <!--&lt;!&ndash;                  <li class="step h1 font-weight-bold">Step 01.</li>&ndash;&gt;-->
    <!--                  <li class="h1 font-weight-bold">Step 01.</li>-->
    <!--                  <li class="step-icon">-->
    <!--                    <i class="mdi mdi-chevron-double-right mdi-36px"></i>-->
    <!--                  </li>-->
    <!--                </ul>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </el-col>-->
    <!--          &lt;!&ndash;end col&ndash;&gt;-->

    <!--          <el-col :span="6" :xs="24" class="mt-4">-->
    <!--            <div class="card work-process border-0 rounded shadow">-->
    <!--              <div class="card-body">-->
    <!--                <h4 class="title">Purchase a contract</h4>-->
    <!--                <p>-->
    <!--                  Choose one of the most profitable contracts and start cloud mining.-->
    <!--                </p>-->
    <!--                <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">-->
    <!--                  <li class="h1 mb-0 font-weight-bold">Step 02.</li>-->
    <!--                  <li class="step-icon">-->
    <!--                    <i class="mdi mdi-chevron-double-right mdi-36px"></i>-->
    <!--                  </li>-->
    <!--                </ul>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </el-col>-->
    <!--          &lt;!&ndash;end col&ndash;&gt;-->

    <!--          <el-col :span="6" :xs="24" class="mt-4">-->
    <!--            <div class="card work-process border-0 rounded shadow">-->
    <!--              <div class="card-body">-->
    <!--                <h4 class="title">Start Mining</h4>-->
    <!--                <p>-->
    <!--                  After purchasing a plan, mining will start automatically without any action required.-->
    <!--                </p>-->
    <!--                <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">-->
    <!--                  <li class="h1 mb-0 font-weight-bold">Step 03.</li>-->
    <!--                  <li class="step-icon">-->
    <!--                    <i class="mdi mdi-chevron-double-right mdi-36px"></i>-->
    <!--                  </li>-->
    <!--                </ul>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </el-col>-->
    <!--          &lt;!&ndash;end col&ndash;&gt;-->
    <!--          <el-col :span="6" :xs="24" class="mt-4">-->
    <!--            <div class="card work-process border-0 rounded shadow">-->
    <!--              <div class="card-body">-->
    <!--                <h4 class="title">Get paid</h4>-->
    <!--                <p>-->
    <!--                  Withdraw your mining income to your crypto wallet after the contract plan expires-->
    <!--                </p>-->
    <!--                <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">-->
    <!--                  <li class="h1 mb-0 font-weight-bold">Step 04.</li>-->
    <!--                  <li class="step-icon">-->
    <!--                    <i class="mdi mdi-check-all mdi-36px"></i>-->
    <!--                  </li>-->
    <!--                </ul>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </el-col>-->
    <!--          &lt;!&ndash;end col&ndash;&gt;-->
    <!--        </el-row>-->
    <!--        &lt;!&ndash;end row&ndash;&gt;-->
    <!--        &lt;!&ndash; 推荐 &ndash;&gt;-->
    <!--&lt;!&ndash;        <div class="mt-100 mt-60 col-12 text-center">&ndash;&gt;-->
    <!--&lt;!&ndash;          <div class="section-title mb-4 pb-2">&ndash;&gt;-->
    <!--&lt;!&ndash;            <h3>Referral Plan</h3>&ndash;&gt;-->
    <!--&lt;!&ndash;            <p class="title text-center mb-3">We have a referral program which allows you to make huge sums of money by inviting new customers to us.Get 3% of every order they buy. </p>&ndash;&gt;-->
    <!--&lt;!&ndash;            <p class="title text-center mb-3">Refer your family, friends, colleagues and earn extra cash!</p>&ndash;&gt;-->
    <!--&lt;!&ndash;            <p class="para-desc mx-auto mb-0"> Example: A customer buys a contract worth $1000 - your&ndash;&gt;-->
    <!--&lt;!&ndash;              commission is $30.</p>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--&lt;!&ndash;        </div>&ndash;&gt;-->
    <!--      </div>-->

    <!--      &lt;!&ndash; 关于我们1 &ndash;&gt;-->
    <!--&lt;!&ndash;      <div class="container mt-100 mt-60">&ndash;&gt;-->
    <!--&lt;!&ndash;        <div class="row align-items-center">&ndash;&gt;-->
    <!--&lt;!&ndash;          <div class="col-lg-6 col-md-5" style="overflow: hidden;">&ndash;&gt;-->
    <!--&lt;!&ndash;            <div class="position-relative">&ndash;&gt;-->
    <!--&lt;!&ndash;              <div class="text-center text-md-left">&ndash;&gt;-->
    <!--&lt;!&ndash;                <img src="images/app/victory.png" class="img-fluid" alt="">&ndash;&gt;-->
    <!--&lt;!&ndash;              </div>&ndash;&gt;-->
    <!--&lt;!&ndash;            </div>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--&lt;!&ndash;          &lt;!&ndash;end col&ndash;&gt;&ndash;&gt;-->

    <!--&lt;!&ndash;          <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">&ndash;&gt;-->
    <!--&lt;!&ndash;            <div class="section-title">&ndash;&gt;-->
    <!--&lt;!&ndash;              <h1 class="title mb-3">Beautiful, Simple & <br> Easy to Use</h1>&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;  text-muted   &ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              <p class="para-desc">Effortlessly order long and short-term mining plans with low thresholds&ndash;&gt;-->
    <!--&lt;!&ndash;                and low fees.</p>&ndash;&gt;-->
    <!--&lt;!&ndash;              <ul class="list-unstyled text-muted">&ndash;&gt;-->
    <!--&lt;!&ndash;                <li class="mb-0"><span class="text-primary h5 mr-2"><i&ndash;&gt;-->
    <!--&lt;!&ndash;                      class="uil uil-check-circle align-middle"></i></span>No need to buy equipment&ndash;&gt;-->
    <!--&lt;!&ndash;                </li>&ndash;&gt;-->
    <!--&lt;!&ndash;                <li class="mb-0"><span class="text-primary h5 mr-2"><i&ndash;&gt;-->
    <!--&lt;!&ndash;                      class="uil uil-check-circle align-middle"></i></span>No maintenance fee&ndash;&gt;-->
    <!--&lt;!&ndash;                </li>&ndash;&gt;-->
    <!--&lt;!&ndash;                <li class="mb-0"><span class="text-primary h5 mr-2"><i&ndash;&gt;-->
    <!--&lt;!&ndash;                      class="uil uil-check-circle align-middle"></i></span>No equipment downtime&ndash;&gt;-->
    <!--&lt;!&ndash;                </li>&ndash;&gt;-->
    <!--&lt;!&ndash;                <li class="mb-0"><span class="text-primary h5 mr-2"><i&ndash;&gt;-->
    <!--&lt;!&ndash;                      class="uil uil-check-circle align-middle"></i></span>Mining starts immediately after payment&ndash;&gt;-->
    <!--&lt;!&ndash;                </li>&ndash;&gt;-->

    <!--&lt;!&ndash;              </ul>&ndash;&gt;-->
    <!--&lt;!&ndash;              <div class="mt-4">&ndash;&gt;-->
    <!--&lt;!&ndash;                <a href="/auth-login" class="btn btn-primary">JOIN US <i class="mdi mdi-chevron-right"></i></a>&ndash;&gt;-->
    <!--&lt;!&ndash;              </div>&ndash;&gt;-->
    <!--&lt;!&ndash;            </div>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--&lt;!&ndash;          &lt;!&ndash;end col&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;        </div>&ndash;&gt;-->
    <!--&lt;!&ndash;        &lt;!&ndash;end row&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;end container&ndash;&gt;-->

    <!--      &lt;!&ndash; 我们的特色 &ndash;&gt;-->
    <!--&lt;!&ndash;      <div class="container mt-100 mt-60">&ndash;&gt;-->
    <!--&lt;!&ndash;        <h4 class="col-12 text-center title mb-5">Advantages of StavlinShop</h4>&ndash;&gt;-->
    <!--&lt;!&ndash;        <Features :featuresData="featuresData" />&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->

    <!--      &lt;!&ndash; 关于我们2 &ndash;&gt;-->
    <!--&lt;!&ndash;      <div class="container mt-100 mt-60">&ndash;&gt;-->
    <!--&lt;!&ndash;        <div class="row align-items-center">&ndash;&gt;-->
    <!--&lt;!&ndash;          <div class="col-lg-5 col-md-6">&ndash;&gt;-->
    <!--&lt;!&ndash;            <img src="images/illustrator/analyze_report_4.svg" alt="" />&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--&lt;!&ndash;          &lt;!&ndash;end col&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;          <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">&ndash;&gt;-->
    <!--&lt;!&ndash;            <div class="section-title ml-lg-5">&ndash;&gt;-->
    <!--&lt;!&ndash;              <h4 class="title mb-4">&ndash;&gt;-->
    <!--&lt;!&ndash;                About us Advanced Cloud Mining Technology&ndash;&gt;-->
    <!--&lt;!&ndash;              </h4>&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash;              <p class="text-muted">&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              <p>&ndash;&gt;-->
    <!--&lt;!&ndash;                Cloud mining is the easiest and most efficient way to make money from cryptocurrency mining without&ndash;&gt;-->
    <!--&lt;!&ndash;                having to buy and maintain your equipment.&ndash;&gt;-->
    <!--&lt;!&ndash;              </p>&ndash;&gt;-->
    <!--&lt;!&ndash;              <p>&ndash;&gt;-->
    <!--&lt;!&ndash;                One of the main advantages of this type of investment is that you only need to select a contract plan&ndash;&gt;-->
    <!--&lt;!&ndash;                and buy it.&ndash;&gt;-->
    <!--&lt;!&ndash;              </p>&ndash;&gt;-->
    <!--&lt;!&ndash;              <p>&ndash;&gt;-->
    <!--&lt;!&ndash;                Thus obtain a stable passive income without the unnecessary risks and difficulties of independent&ndash;&gt;-->
    <!--&lt;!&ndash;                mining!&ndash;&gt;-->
    <!--&lt;!&ndash;              </p>&ndash;&gt;-->

    <!--&lt;!&ndash;              &lt;!&ndash;              <ul class="list-unstyled text-muted">&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                <li class="mb-0">&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                  <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>Digital&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                  Marketing Solutions for Tomorrow&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                </li>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                <li class="mb-0">&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                  <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>Our&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                  Talented & Experienced Marketing Agency&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                </li>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                <li class="mb-0">&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                  <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>Create your&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                  own skin to match your brand&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;                </li>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;              &lt;!&ndash;              </ul>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;            </div>&ndash;&gt;-->
    <!--&lt;!&ndash;          </div>&ndash;&gt;-->
    <!--&lt;!&ndash;          &lt;!&ndash;end col&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;        </div>&ndash;&gt;-->
    <!--&lt;!&ndash;        &lt;!&ndash;end row&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;end container&ndash;&gt;-->
    <!--    </section>-->
    <!-- 登录与未登录通用 -->
    <section class="section pt-20" style="background-color: rgba(247,250,255, 0.9)">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Choose Your Plans</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                We offer you the most profitable and reliable cloud mining by providing daily payouts.
                Start cryptocurrency and cloud mining today, and get the first payout tomorrow!
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>

      <div>
        <!--        background-color: #fafafa;border-radius: 10px;padding: 25px;-->
        <div class="container" style="">
<!--          <h4 v-if="!login" class="mt-1 col-12 text-center mb-4">Choose Your Plans</h4>-->
<!--          <h4 v-else>Choose Your Plan</h4>-->
          <el-row v-if="plans==undefined" v-loading="plans==undefined" element-loading-background="rgba(0, 0, 0, 0)">
          </el-row>
          <el-row v-else>
            <Plans :data="plans.result"></Plans>
          </el-row>
          <!-- <div class="mt-3" v-if="login">
            <div v-if="plans!=undefined" class="d-grid gap-2">
              <router-link v-if="plansLoad == 0" class="btn btn-primary btn-block" to="/buy-plans">See More</router-link>
              <b-button v-else-if="plansLoad == 1" block class="btn btn-primary disabled">Loading...</b-button>
            </div>
          </div> -->
        </div>
      </div>

    </section>

    <section class="section pb-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">What You Will Get As An Affiliate Partner</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Join our referral program, earn a lifetime 3% commission, run your business like a boss! You can start
                earning money
                even if you do not invest. For example,if someone purchases a $100 order using your referral code,
                you'll get $3 for
                free. With unlimited referrals your earning potential is endless!
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div>
          <div class="col-12 mt-4 pt-2 text-center">
            <a href="/auth-login" class="btn btn-primary">Join Us <i class="mdi mdi-arrow-right"></i></a>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--    Latest Transactions-->
    <!--    交易记录-->
    <transactions></transactions>

    <section class="section pt-0">
      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Our Valuable Clients</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                We have multiple investors invest with us. Some impressions from our Customers!
                Read some of the lovely things our Customers say about us.
              </p>
            </div>
          </div>
        </div>
        <el-row>
          <el-col :span="24" class="mt-4">
            <Testimonial :testimonialData="testimonialData"/>
          </el-col>
        </el-row>
      </div>
    </section>

    <!--    Latest Transactions-->

    <!--    <section class="section huadonlist">-->
    <!--      <div class="row justify-content-center">-->
    <!--        <div class="col-12 text-center">-->
    <!--          <div class="section-title mb-4 pb-2">-->
    <!--            <h4 class="title mb-4">Latest Transactions</h4>-->
    <!--            <p class="text-muted para-desc mx-auto mb-0">-->
    <!--              Our goal is to simplify investing so that anyone can be an investor. Our members come from all over the world, they-->
    <!--              trade all the time, our platform is completely authentic.-->
    <!--            </p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <el-row v-if="huadon.lastWithdrawList == undefined && huadon.lastDepositList==undefined" v-loading="huadon.lastWithdrawList == undefined && huadon.lastDepositList == undefined" element-loading-background="rgba(0, 0, 0, 0)">-->
    <!--      </el-row>-->
    <!--      <el-row v-else type="flex" style="flex-wrap: wrap;" justify="space-around">-->
    <!--        <el-col :xs="24" :span="10" class="m-2">-->
    <!--          <div>-->
    <!--            <div class="text-center m-5">-->
    <!--              <span style="border-bottom:2px solid #2f55d4;color: #2f55d4;font-weight: bold;">Last Deposit</span>-->
    <!--            </div>-->
    <!--            <vueSeamlessScroll :data="huadon.lastDepositList" :class-option="options" class="seamless-warp">-->
    <!--              <div>-->
    <!--                <div v-for="(it,i) in huadon.lastDepositList" :key="i">-->
    <!--                  <div class="card m-2 p-2" style="font-weight: bold;box-shadow:0 0 3px rgba(60, 72, 88, 0.15) !important;border: none;">-->
    <!--                    <div>{{it.email}}</div>-->
    <!--                    <div style="color: red;">${{ it.amount * it.usdtExchangeRate }}</div>-->
    <!--                    <div>{{ getDate(it.updatedAt) }}</div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </vueSeamlessScroll>-->
    <!--          </div>-->
    <!--        </el-col>-->
    <!--        <el-col :xs="24" :span="10" class="m-2">-->
    <!--          <div>-->
    <!--            <div class="text-center m-5">-->
    <!--              <span style="border-bottom:2px solid #2f55d4;color: #2f55d4;font-weight: bold;">Last Withdraw</span>-->
    <!--            </div>-->
    <!--            <vueSeamlessScroll :data="huadon.lastWithdrawList" :class-option="options" class="seamless-warp">-->
    <!--              <div>-->
    <!--                <div v-for="(it,i) in huadon.lastWithdrawList" :key="i">-->
    <!--                  <div class="card m-2 p-2" style="font-weight: bold;box-shadow:0 0 3px rgba(60, 72, 88, 0.15) !important;border: none;">-->
    <!--                    <div>{{it.email}}</div>-->
    <!--                    <div style="color: red;">${{ it.amount * it.usdtExchangeRate }}</div>-->
    <!--                    <div>{{ getDate(it.updatedAt) }}</div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </vueSeamlessScroll>-->
    <!--          </div>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </section>-->

    <!--     合作伙伴-->
    <our-partners></our-partners>

    <!--    <section class="section huadonlist">-->
    <!--      <div class="row justify-content-center">-->
    <!--        <div class="col-12 text-center">-->
    <!--          <div class="section-title mb-4 pb-2">-->
    <!--            <h4 class="title mb-4">Our Partners</h4>-->
    <!--            <div>-->
    <!--              <el-row class="" type="flex" justify="center" style="flex-wrap: wrap;" >-->
    <!--                <el-col v-for="(it,i) in imagesPranters" :key="i" class="m-3" :span="4"-->
    <!--                  style="width:180px;height: 75px;box-shadow:0 0 3px rgba(60, 72, 88, 0.15) !important;border-radius: 15px;overflow: hidden;">-->

    <!--                  <img :src="require('../assets/partner/' + it + '.png')" width="100%" height="100%"/>-->
    <!--&lt;!&ndash;                  <img src="../assets/partner/" alt="">&ndash;&gt;-->
    <!--                </el-col>-->
    <!--              </el-row>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->


    <section class="section huadonlist">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">We accept A wide range of payment systems</h4>
            <p class="text-muted para-desc mx-auto mb-0">
              For the investors' convenience, IncCrypto offers a wide range of payment systems for depositing funds and
              receiving
              payments.
            </p>
          </div>
        </div>
      </div>
      <div style="display: flex;align-items: center;justify-content: center">
        <div class="slider__wallet--img  mb-10">
          <ul class="slider__wallet--img__wrapper d-flex justify-content-center">
            <li class="slider__wallet--img__items" v-for="item in payments" :key="item">
              <a class="slider__wallet--img__link" data-bs-toggle="tooltip" data-bs-placement="top"
                 href="javascript:void(0);" data-bs-original-title="Bitcoin" aria-label="Bitcoin" data-original-title=""
                 title="">
                <img :src="item">
              </a>
            </li>
            <!--            <li class="slider__wallet&#45;&#45;img__items"><a class="slider__wallet&#45;&#45;img__link" data-bs-toggle="tooltip" data-bs-placement="top" href="javascript:void(0);" data-bs-original-title="Ethereum" aria-label="Ethereum" data-original-title="" title=""><img src="assets/img/coin/eth.svg"></a></li>-->
            <!--            <li class="slider__wallet&#45;&#45;img__items"><a class="slider__wallet&#45;&#45;img__link" data-bs-toggle="tooltip" data-bs-placement="top" href="javascript:void(0);" data-bs-original-title="Tether" aria-label="Tether" data-original-title="" title=""><img src="assets/img/coin/usdt.svg"></a></li>-->
            <!--            <li class="slider__wallet&#45;&#45;img__items"><a class="slider__wallet&#45;&#45;img__link" data-bs-toggle="tooltip" data-bs-placement="top" href="javascript:void(0);" data-bs-original-title="Litecoin" aria-label="Litecoin" data-original-title="" title=""><img src="assets/img/coin/ltc.svg"></a></li>-->
            <!--            <li class="slider__wallet&#45;&#45;img__items"><a class="slider__wallet&#45;&#45;img__link" data-bs-toggle="tooltip" data-bs-placement="top" href="javascript:void(0);" data-bs-original-title="Ripple" aria-label="Ripple" data-original-title="" title=""><img style="width:36px;" src="assets/img/coin/riple.svg"></a></li>-->
            <!--            <li class="slider__wallet&#45;&#45;img__items"><a class="slider__wallet&#45;&#45;img__link" data-bs-toggle="tooltip" data-bs-placement="top" href="javascript:void(0);" data-bs-original-title="Dash" aria-label="Dash" data-original-title="" title=""><img style="width:36px;" src="assets/img/coin/dash.svg"></a></li>-->
            <!--            <li class="slider__wallet&#45;&#45;img__items"><a class="slider__wallet&#45;&#45;img__link" data-bs-toggle="tooltip" data-bs-placement="top" href="javascript:void(0);" data-bs-original-title="USDC" aria-label="USDC" data-original-title="" title=""><img style="width:36px;" src="assets/img/coin/usdc.svg"></a></li>-->
            <!--            <li class="slider__wallet&#45;&#45;img__items"><a class="slider__wallet&#45;&#45;img__link" data-bs-toggle="tooltip" data-bs-placement="top" href="javascript:void(0);" data-bs-original-title="Bch" aria-label="BCH" data-original-title="" title=""><img style="width:36px;" src="assets/img/coin/bch.svg"></a></li>-->
          </ul>
        </div>
        <!--        <el-row type="flex" style="flex-wrap: wrap;" justify="center">-->
        <!--          <el-col v-for="(it,i) in payments" :key="i" class="m-3" :span="4"-->
        <!--            style="padding:5px;width:75px;height: 50px;box-shadow:0 0 3px rgba(60, 72, 88, 0.15) !important;border-radius: 15px;overflow: hidden;">-->
        <!--            <img style="object-fit:contain;" :src="it" width="100%" height="100%" />-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </div>
    </section>
    <Footer/>
<!--    <FooterBar></FooterBar>-->
    <!-- Footer End -->
    <!-- FooterNew End -->
<!--    <Switcher/>-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
       v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->

  </div>
</template>

<style scoped lang="scss">

#promotions-popup{
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  .my-shark{
    animation:login-shake 0.8s 1 ease-in;
  }
  @keyframes login-shake {
    0% {transform: scale(1);}
    10%, 20% {transform: scale(0.9) rotate(-3deg);}
    30%, 50%, 70%, 90% {transform: scale(1.1) rotate(3deg);}
    40%, 60%, 80% {transform: scale(1.1) rotate(-3deg);}
    100% {transform: scale(1) rotate(0);}
  }
}


.seamless-warp {
  height: 300px;
  overflow: hidden;
}

.huadonlist {
  padding: 15px;
  @media (min-width: 768px) {
    padding: 50px 160px;
  }
}

.slider__wallet--img {
  background: #f9f9f9;
  border: 1px solid #646464;
  padding: .625rem;
  display: inline-block;
  border-radius: 5px;
  margin: 1.875rem auto 0;

  ul {
    margin: 0;
    padding: 0
  }

  ul:last-child {
    margin-bottom: 0
  }

  li {
    list-style: none;
    line-height: 1
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    transition: all 0.3s ease 0s;
  }
}

@media only screen and (min-width: 576px) {
  .slider__wallet--img {
    padding: 0.8125rem
  }
}

@media only screen and (min-width: 768px) {
  .slider__wallet--img {
    padding: 0.9375rem
  }
}

@media only screen and (min-width: 992px) {
  .slider__wallet--img {
    margin-top: 3.125rem
  }
}

@media only screen and (min-width: 1200px) {
  .slider__wallet--img {
    margin-top: 3.4375rem
  }
}

@media only screen and (min-width: 1600px) {
  .slider__wallet--img {
    margin-top: 5rem
  }
}

.slider__wallet--img__wrapper {
  gap: .5rem
}

@media only screen and (min-width: 576px) {
  .slider__wallet--img__wrapper {
    gap: 0.8125rem
  }
}

@media only screen and (min-width: 768px) {
  .slider__wallet--img__wrapper {
    gap: 1.25rem
  }
}

@media only screen and (min-width: 1200px) {
  .slider__wallet--img__wrapper {
    gap: 1.875rem
  }
}

.slider__wallet--img__link {
  border: 1px solid #e4e4e4;
  padding: 5px;
  border-radius: 5px;
  display: block
}

@media only screen and (min-width: 768px) {
  .slider__wallet--img__link {
    padding: 6px
  }
}
</style>
