<template>

  <div>
    <div class="brand__logo--section section--padding" style="background:#ffffff;">
      <div class="container">
        <div class="section__heading text-center mb-50 ">
          <h2 class="section__heading--maintitle mb-15">Our Partners</h2>
        </div>
        <div class="row  " :class="[isMobile ? 'row-cols-2' : 'row-cols-5']">
          <div class="col single__brand--logo " v-for="(it,i) in imagesPranters">
            <img class="logo__light" :src="require('../assets/partner/' + it + '.png')">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurPartners",
  data() {
    return {
      imagesPranters: [
        "google",
        "forbes",
        "yahoo",
        "youtube",
        "BINANCE",
        "coinbase",
        "coinpedia",
        "AMBCRYPTO",
        "BENZINGA",
        "CoinGape",
        "GlobeNewswire",
        "cryptionews",
        "AnalyticsInsight",
        "SOURECFORGE",
        "MarketWatch",
      ],
      isMobile: false
    }
  },
  created() {
    const that = this;
    if (window.innerWidth < 540) {
      that.isMobile = true
    } else {
      that.isMobile = false
    }
  },
  mounted() {
    const that = this;
    window.addEventListener('resize', function ($event) {
      // console.log($event)
      if (window.innerWidth < 540) {
        that.isMobile = true
      } else {
        that.isMobile = false
      }
    })
  },
  destroyed() {
    window.removeEventListener('resize', function () {
      console.log('delete listener')
    })
  }
}
</script>

<style scoped lang="scss">
@import '@/style/common.scss';

.brand__logo--section {
  img {
    max-width: 100%;
    height: auto;
    display: block;
    transition: all 0.3s ease 0s;
  }

  .brand__logo--section {
    background: #f9f9f9
  }

  @media only screen and (max-width: 767px) {
    .single__brand--logo {
      width: 25%
    }
  }

  @media only screen and (max-width: 479px) {
    .single__brand--logo {
      width: 45%
    }
  }

  .single__brand--logo:hover img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
}
</style>