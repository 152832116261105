<template>
  <div class="homeBanner">
    <section class="hero__slider--section style2">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-lg-6">
            <b-carousel
                ref="myCarousel"
                id="testimonialSwiper"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                style="border-radius: 10px;overflow: hidden;height: 100%"
            >
              <router-link to="/auth-login">
<!--                <b-carousel-slide style="cursor: pointer;height: 100%" :img-src="url" >-->
<!--                  <div class=" w-100">-->
<!--                    <div class="title-heading">-->

<!--                      &lt;!&ndash;                    <div class="heading mb-3">&ndash;&gt;-->
<!--                      &lt;!&ndash;                      Start Cloud<br />&ndash;&gt;-->
<!--                      &lt;!&ndash;                      &lt;!&ndash;                <span class="text-primary">Mining Now! </span>&ndash;&gt;&ndash;&gt;-->
<!--                      &lt;!&ndash;                      Mining Now!&ndash;&gt;-->
<!--                      &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                      &lt;!&ndash;                    <p class="para-desc">&ndash;&gt;-->
<!--                      &lt;!&ndash;                      StavlinShop is one of the leading cryptocurrency mining platforms, offering cryptocurrency mining capacities in every range - for newcomers. Our mission is to make acquiring cryptocurrencies easy and fast for everyone.&ndash;&gt;-->
<!--                      &lt;!&ndash;                    </p>&ndash;&gt;-->
<!--                      &lt;!&ndash;                    <div class="mt-4">&ndash;&gt;-->
<!--                      &lt;!&ndash;                      <router-link to="/auth-login" class="btn btn-primary mt-2 mr-2">Get Started</router-link>&ndash;&gt;-->
<!--                      &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </b-carousel-slide>-->
<!--                <b-carousel-slide style="cursor: pointer;height: 100%" :img-src="secondUrl" >-->
<!--                  <div class=" w-100">-->
<!--                    <div class="title-heading"></div>-->
<!--                  </div>-->
<!--                </b-carousel-slide>-->
                <b-carousel-slide style="cursor: pointer;height: 100%" :img-src="thirdUrl" >
                  <div class=" w-100">
                    <div class="title-heading"></div>
                  </div>
                </b-carousel-slide>
              </router-link>

                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
            </b-carousel>

<!--            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">-->
<!--              <ol class="carousel-indicators">-->
<!--                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>-->
<!--                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>-->
<!--                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>-->
<!--              </ol>-->
<!--              <div class="carousel-inner">-->
<!--                <div class="carousel-item active">-->
<!--                  <img src="http://code.z01.com/img/2016instbg_01.jpg" class="d-block w-100" alt="...">-->
<!--                </div>-->
<!--                <div class="carousel-item">-->
<!--                  <img src="http://code.z01.com/img/2016instbg_02.jpg" class="d-block w-100" alt="...">-->
<!--                </div>-->
<!--                <div class="carousel-item">-->
<!--                  <img src="http://code.z01.com/img/2016instbg_03.jpg" class="d-block w-100" alt="...">-->
<!--                </div>-->
<!--              </div>-->
<!--              <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">-->
<!--                <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
<!--                <span class="sr-only">Previous</span>-->
<!--              </a>-->
<!--              <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">-->
<!--                <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
<!--                <span class="sr-only">Next</span>-->
<!--              </a>-->
<!--            </div>-->
          </div>
          <div class="col-xl-5 col-lg-6">
            <div class="sidebar__product mb--n30 d-sm-u-none ">
              <article class="product__card--horizontall d-flex mb-10" style="padding:  0.8125rem;">
                <div class="product__card--horizontall__thumbnail" style="width:150px;height:150px;">
                  <a class="product__card--thumbnail__link display-block" href="/auth-signup">
                    <img class="product__card--thumbnail__img " style="width:150px;height:150px;"
                         src="../assets/img/other/register1.png">
                  </a>
                </div>
                <div class="product__card--horizontall__content">
                  <h3 class="product__card--horizontall__title product__card--horizontall__title"
                      style="display: flex;gap: 1.25rem;">
                    <img style="width:30px;height:30px;" src="../assets/img/other/no1.png">
                    <a href="/auth-signup">Create An Account </a>
                  </h3>
                  <span class="product__card--author__text">
Join our community by filling out the registration form.
</span>
                </div>
              </article>
              <article class="product__card--horizontall d-flex mb-10" style="padding: 0.8125rem;">
                <div class="product__card--horizontall__thumbnail" style="width:150px;height:150px;">
                  <a class="product__card--thumbnail__link display-block" href="/plans">
                    <img class="product__card--thumbnail__img " style="width:150px;height:150px;"
                         src="../assets/img/other/register2.png">
                  </a>
                </div>
                <div class="product__card--horizontall__content">
                  <h3 class="product__card--horizontall__title product__card--horizontall__title"
                      style="display: flex;gap: 1.25rem;">
                    <img style="width:30px;height:30px;" src="../assets/img/other/no2.png">
                    <a href="/plans">Choose Your Plan</a>
                  </h3>
                  <span class="product__card--author__text">
Choose one of the most profitable contracts and start cloud mining.
</span>
                </div>
              </article>
              <article class="product__card--horizontall d-flex mb-30" style="padding: 0.8125rem;">
                <div class="product__card--horizontall__thumbnail" style="width:150px;height:150px;">
                  <a class="product__card--thumbnail__link display-block" href="/dashboard">
                    <img class="product__card--thumbnail__img " style="width:150px;height:150px;"
                         src="../assets/img/other/register3.png">
                  </a>
                </div>
                <div class="product__card--horizontall__content">
                  <h3 class="product__card--horizontall__title product__card--horizontall__title"
                      style="display: flex;gap: 1.25rem;">
                    <img style="width:30px;height:30px;" src="../assets/img/other/no3.png">
                    <a href="/dashboard">Receive Payout Everyday </a>
                  </h3>
                  <span class="product__card--author__text">
Withdraw your mining income on a daily basis to your crypto wallet.
</span>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div>
        <!--      <div class="container" style="vertical-align: middle;padding-top: 20px;">-->
        <!--        <script async="" src="https://b.sf-syn.com/badge_js?sf_id=3561296&amp;variant_id=sd"></script>-->
        <!--        <script async="" src="https://b.sf-syn.com/badge_js?sf_id=3561296&amp;variant_id=sf"></script>-->
        <!--        <script type="text/javascript">-->
        <!--          baseUrl = "https://widgets.cryptocompare.com/";-->
        <!--          var scripts = document.getElementsByTagName("script");-->
        <!--          var embedder = scripts[scripts.length - 1];-->
        <!--          (function () {-->
        <!--            var appName = encodeURIComponent(window.location.hostname);-->
        <!--            if (appName == "") {-->
        <!--              appName = "local";-->
        <!--            }-->
        <!--            var s = document.createElement("script");-->
        <!--            s.type = "text/javascript";-->
        <!--            s.async = true;-->
        <!--            var theUrl = baseUrl + 'serve/v1/coin/chartscroller?fsyms=BTC,ETH,LTC,XRP,USDT,USDC,BCH,DOGE,DASH&tsyms=USD';-->
        <!--            s.src = theUrl + (theUrl.indexOf("?") >= 0 ? "&" : "?") + "app=" + appName;-->
        <!--            embedder.parentNode.appendChild(s);-->
        <!--          })();-->
        <!--        </script>-->
        <!--        <script type="text/javascript" async=""-->
        <!--                src="https://widgets.cryptocompare.com/serve/v1/coin/chartscroller?fsyms=BTC,ETH,LTC,XRP,USDT,USDC,BCH,DOGE,DASH&amp;tsyms=USD&amp;app=bytebus.com"></script>-->
        <!--        <div class="ccc-widget ccc-chartscroller-v1 is-init" id="marquee-container" data-pausable="bool"-->
        <!--             data-speed="0.40"-->
        <!--             style="background: rgb(255, 255, 255); box-sizing: border-box; line-height: 1; overflow: hidden;">-->
        <!--          <div class="marquee3k__wrapper"-->
        <!--               style="white-space: nowrap; transform: translate(-211.6px, 0px) translateZ(0px);">-->
        <!--            <div id="marquee-inner" class="ccc-chartscroller-v1__copy" style="display: inline-block;">-->
        <!--              <div class="ccc-coin-chartscroller-v1-container" id="marquee-element"-->
        <!--                   style="margin: 0px; padding: 0px; transition: all 1s linear 0s;">-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/btc/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/BTC/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">BTC: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_BTC_USD">$ 16,754.2</span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_BTC_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctBTC_USD">3.94 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/eth/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/ETH/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">ETH: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_ETH_USD">$ 1,258.39</span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_ETH_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctETH_USD">5.77 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/ltc/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/LTC/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">LTC: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_LTC_USD">$ 57.95</span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_LTC_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctLTC_USD">2.31 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/xrp/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/XRP/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">XRP: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_XRP_USD">$ 0.3682</span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_XRP_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctXRP_USD">11.52 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/usdt/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/USDT/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">USDT: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_USDT_USD">$ 0.9988</span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_USDT_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctUSDT_USD">0.01 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/usdc/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/USDC/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">USDC: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_USDC_USD">$ 1.00</span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_USDC_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctUSDC_USD">0.00 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/bch/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/BCH/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">BCH: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_BCH_USD">$ 103.84</span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_BCH_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctBCH_USD">6.38 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/doge/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/DOGE/USD/latest.png?ts=1668477523049">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">DOGE: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_DOGE_USD">$ 0.08665</span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_DOGE_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctDOGE_USD">6.80 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/dash/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/DASH/USD/latest.png?ts=1668477523049">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">DASH: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_DASH_USD">$ 34.45</span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_DASH_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctDASH_USD">5.82 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <style></style>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div id="marquee-inner" class="ccc-chartscroller-v1__copy" style="display: inline-block;">-->
        <!--              <div class="ccc-coin-chartscroller-v1-container" id="marquee-element"-->
        <!--                   style="margin: 0px; padding: 0px; transition: all 1s linear 0s;">-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/btc/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/BTC/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">BTC: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_BTC_USD">-->
        <!--                                            $ 16,700.3-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_BTC_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctBTC_USD">3.94 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/eth/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/ETH/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">ETH: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_ETH_USD">-->
        <!--                                            $ 1,256.44-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_ETH_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctETH_USD">5.77 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/ltc/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/LTC/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">LTC: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_LTC_USD">-->
        <!--                                            $ 57.60-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_LTC_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctLTC_USD">2.31 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/xrp/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/XRP/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">XRP: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_XRP_USD">-->
        <!--                                            $ 0.3716-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_XRP_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctXRP_USD">11.52 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/usdt/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/USDT/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">USDT: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_USDT_USD">-->
        <!--                                            $ 0.9988-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_USDT_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctUSDT_USD">0.01 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/usdc/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/USDC/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">USDC: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_USDC_USD">-->
        <!--                                            $ 1.00-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_USDC_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctUSDC_USD">0.00 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/bch/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/BCH/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">BCH: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_BCH_USD">-->
        <!--                                            $ 104.12-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_BCH_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctBCH_USD">6.38 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/doge/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/DOGE/USD/latest.png?ts=1668477523049">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">DOGE: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_DOGE_USD">-->
        <!--                                            $ 0.08654-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_DOGE_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctDOGE_USD">6.80 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/dash/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/DASH/USD/latest.png?ts=1668477523049">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">DASH: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_DASH_USD">-->
        <!--                                            $ 34.40-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_DASH_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctDASH_USD">5.82 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <style></style>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div id="marquee-inner" class="ccc-chartscroller-v1__copy" style="display: inline-block;">-->
        <!--              <div class="ccc-coin-chartscroller-v1-container" id="marquee-element"-->
        <!--                   style="margin: 0px; padding: 0px; transition: all 1s linear 0s;">-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/btc/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/BTC/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">BTC: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_BTC_USD">-->
        <!--                                            $ 16,700.3-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_BTC_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctBTC_USD">3.94 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/eth/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/ETH/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">ETH: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_ETH_USD">-->
        <!--                                            $ 1,256.44-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_ETH_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctETH_USD">5.77 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/ltc/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/LTC/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">LTC: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_LTC_USD">-->
        <!--                                            $ 57.60-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_LTC_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctLTC_USD">2.31 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/xrp/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/XRP/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">XRP: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_XRP_USD">-->
        <!--                                            $ 0.3716-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_XRP_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctXRP_USD">11.52 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/usdt/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/USDT/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">USDT: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_USDT_USD">-->
        <!--                                            $ 0.9988-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_USDT_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctUSDT_USD">0.01 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/usdc/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/USDC/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">USDC: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_USDC_USD">-->
        <!--                                            $ 1.00-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_USDC_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctUSDC_USD">0.00 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/bch/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/BCH/USD/latest.png?ts=1668477523048">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">BCH: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_BCH_USD">-->
        <!--                                            $ 104.12-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_BCH_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctBCH_USD">6.38 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/doge/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/DOGE/USD/latest.png?ts=1668477523049">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">DOGE: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_DOGE_USD">-->
        <!--                                            $ 0.08654-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_DOGE_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctDOGE_USD">6.80 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--                <div class="ccc-chartscroller-v1-price-container">-->
        <!--                  <a href="https://www.cryptocompare.com/coins/dash/overview/usd" target="_blank" rel="nofollow">-->

        <!--                    <div class="ccc-chart-spark">-->
        <!--                      <img src="https://images.cryptocompare.com/sparkchart/DASH/USD/latest.png?ts=1668477523049">-->
        <!--                    </div>-->

        <!--                    <div class="ccc-chartscroller-v1-text">-->
        <!--                      <span class="ccc-chartscroller-v1-price-name">DASH: </span>-->
        <!--                      <span class="ccc-chartscroller-v1-price-value" style="color: #000;" id="CCCHeader3Price_DASH_USD">-->
        <!--                                            $ 34.40-->
        <!--                                        </span>-->

        <!--                      <span class="ccc-chartscroller-v1-price-change" id="CCCHeader3ChangePctContainer_DASH_USD"-->
        <!--                            style="color: #3d9400;">-->
        <!--                        (<span id="CCCHeader3ChangePctDASH_USD">5.82 %</span>)-->
        <!--                    </span>-->

        <!--                      <img-->
        <!--                          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjE5My4xMjksOTYuODc5IDAsMjg5LjM3OSAzODYuMjU3LDI4OS4zNzkgIiBmaWxsPSIjM2Q5NDAwIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="-->
        <!--                          style="width: 12px; height: 12px; margin-left: 0; vertical-align: inherit; position: inherit; max-width: inherit; left: inherit; right: inherit; top: inherit; bottom: inherit;display:inline-block; line-height: 20px; vertical-align: middle;">-->
        <!--                    </div>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
      </div>

    </section>
  </div>
</template>

<script>
import homeApi from "@/apis/homeApi";

export default {
  name: "HomeBanner",
  data(){
    return {
      slide: 0,
      url: '',
      secondUrl:'',
      thirdUrl: ''
    }
  },
  created() {
    //获取Banner图片
    // homeApi.banner(res => {
    //   this.url = res.data.data
    //   // this.secondUrl="https://stavlin.shop/images/app/banner.jpg"
    //   this.thirdUrl = "https://stavlin.shop/images/app/invite-friends.jpg"
    //
    // })
  },
  methods:{
    toPath(){
      this.$router.push({path: '/auth-login'})
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/style/common.scss';
.homeBanner{
  ::v-deep {
    #testimonialSwiper .carousel-inner{
      height: 100%;
      .img-fluid{
        height: 100%;
      }
    }
  }
}
#testimonialSwiper .carousel-inner{
  height: 100% !important;
}
button {
  background-color: transparent;
  border: none;
}
.homeBanner{
  color: #161c2d;
  //margin-top: 100px;
  font-size: 0.9375rem;
  line-height: 1.625rem;
}
@media only screen and (max-width: 991px) {
  .homeBanner{
    font-size:0.875rem;
    line-height: 1.5rem;
  }
}

@media only screen and (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    max-width: 1350px;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1440px;
  }
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  padding-right: var(--bs-gutter-x, 0.9375rem);
  padding-left: var(--bs-gutter-x, 0.9375rem);
}


.row {
  margin-right: -0.625rem;
  margin-left: -0.625rem
}

@media only screen and (min-width: 992px) {
  .row {
    margin-right: -0.8125rem;
    margin-left: -0.8125rem
  }
}

.row > * {
  padding-right: 0.625rem;
  padding-left: 0.625rem
}

@media only screen and (min-width: 992px) {
  .row > * {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem
  }
}



.hero__slider--section.style2 {
  padding: 1.875rem 0;
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar__product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.9375rem
  }
}

@media only screen and (max-width: 991px) {
  .sidebar__product {
    margin-top: 1.875rem
  }
}
.title-heading {
  line-height: 26px;
  color: #161c2d;
}

.title-heading .heading {
  font-size: 45px !important;
  letter-spacing: 1px;
}


.title-heading .para-desc {
  font-size: 18px;
}

@media (max-width: 1200px) {
  .title-heading .heading {
    font-size: 18px !important;
  }
  .title-heading .para-desc {
    font-size: 12px;
  }
  .title-heading .btn{
    font-size: 14px;
    padding: 5px 16px;
  }
}

@media (max-width: 450px) {
  .title-heading .heading {
    font-size: 16px !important;
  }
  .title-heading .para-desc {
    font-size: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; // 显示2行
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .title-heading .btn{
    font-size: 12px;
    padding: 2px 10px;
  }
}

.product__card--horizontall {
  padding: 0.75rem;
  background: #f9f9f9;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 10px;
  gap: 15px
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product__card--horizontall {
    width: 50%
  }
}

@media only screen and (min-width: 768px) {
  .product__card--horizontall {
    gap: 12px
  }
}

@media only screen and (min-width: 992px) {
  .product__card--horizontall {
    padding: 0.8125rem
  }
}

@media only screen and (min-width: 1200px) {
  .product__card--horizontall {
    padding:  0.9375rem;
    gap: 15px
  }
}

@media only screen and (min-width: 1366px) {
  .product__card--horizontall {
    padding: 1.25rem;
    gap: 20px
  }
}

@media only screen and (max-width: 767px) {
  .product__card--horizontall {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }
}

@media only screen and (max-width: 419px) {
  .product__card--horizontall {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

.product__card--horizontall:hover .product__card--thumbnail__img {
  -webkit-transform: scale(1.09);
  transform: scale(1.09)
}

.product__card--horizontall__thumbnail {
  width: 100%;
  border-radius: 8px;
  overflow: hidden
}

@media only screen and (min-width: 440px) {
  .product__card--horizontall__thumbnail {
    width: 210px
  }
}

@media only screen and (min-width: 576px) {
  .product__card--horizontall__thumbnail {
    width: 240px
  }
}

@media only screen and (min-width: 768px) {
  .product__card--horizontall__thumbnail {
    width: 185px
  }
}

@media only screen and (min-width: 992px) {
  .product__card--horizontall__thumbnail {
    width: 210px
  }
}

@media only screen and (min-width: 1200px) {
  .product__card--horizontall__thumbnail {
    width: 225px
  }
}

@media only screen and (min-width: 1366px) {
  .product__card--horizontall__thumbnail {
    width: 240px
  }
}

@media only screen and (min-width: 1600px) {
  .product__card--horizontall__thumbnail {
    width: 260px
  }
}

.product__card--horizontall__content {
  width: 100%
}

@media only screen and (min-width: 440px) {
  .product__card--horizontall__content {
    width: calc(100% - 210px)
  }
}

@media only screen and (min-width: 576px) {
  .product__card--horizontall__content {
    width: calc(100% - 240px)
  }
}

@media only screen and (min-width: 768px) {
  .product__card--horizontall__content {
    width: calc(100% - 185px)
  }
}

@media only screen and (min-width: 992px) {
  .product__card--horizontall__content {
    width: calc(100% - 210px)
  }
}

@media only screen and (min-width: 1200px) {
  .product__card--horizontall__content {
    width: calc(100% - 225px)
  }
}

@media only screen and (min-width: 1600px) {
  .product__card--horizontall__content {
    width: calc(100% - 240px)
  }
}

@media only screen and (min-width: 1600px) {
  .product__card--horizontall__content {
    width: calc(100% - 260px)
  }
}

@media only screen and (max-width: 991px) {
  .product__card--horizontall__content .product__card--author {
    margin-bottom: 0.625rem
  }
}

@media only screen and (max-width: 991px) {
  .product__card--horizontall__content .product__card--author__text {
    font-size: 0.8125rem
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product__card--horizontall__content .product__card--btn {
    font-size: 0.875rem;
    height: 2.25rem;
    line-height: 2.25rem
  }
}

.product__card--horizontall__title {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom:  0.9375rem;
  padding-bottom:  0.9375rem
}

@media only screen and (min-width: 768px) {
  .product__card--horizontall__title {
    margin-bottom: .625rem;
    padding-bottom: .625rem
  }
}

@media only screen and (min-width: 992px) {
  .product__card--horizontall__title {
    margin-bottom: 0.8125rem;
    padding-bottom:  0.8125rem
  }
}

@media only screen and (min-width: 1366px) {
  .product__card--horizontall__title {
    font-size: 1.125rem;
    margin-bottom: 0.9375rem;
    padding-bottom:  0.9375rem
  }
}
</style>
