<template>
  <section class="contact__info--section mb-30">
    <div class="container">
      <div class="row mb--n30">
        <div class="col-lg-3 col-md-4 col-sm-6 col-6 custom-col mb-30">
          <div class="contact__info--items text-center" style="-webkit-box-shadow: 0px 1px 18px 0px rgb(0 0 0 / 12%);box-shadow: 0px 1px 18px 0px rgb(0 0 0 / 12%);background: #fff;">
            <div class="contact__info--icon" style="height:5rem; width:5rem;line-height:5rem;">
              <img src="../assets/img/icon/resource.png">
            </div>
            <div class="contact__info--content">
              <div class="contact__info--content__title ">230K+ Active User</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-6 custom-col mb-30">
          <div class="contact__info--items text-center" style="-webkit-box-shadow: 0px 1px 18px 0px rgb(0 0 0 / 12%);box-shadow: 0px 1px 18px 0px rgb(0 0 0 / 12%);background: #fff;">
            <div class="contact__info--icon" style="height:5rem; width:5rem;line-height:5rem;">
              <img src="../assets/img/icon/exchange.png">
            </div>
            <div class="contact__info--content">
              <div class="contact__info--content__title ">24/7 Fast Payouts</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-6 custom-col mb-30">
          <div class="contact__info--items text-center" style="-webkit-box-shadow: 0px 1px 18px 0px rgb(0 0 0 / 12%);box-shadow: 0px 1px 18px 0px rgb(0 0 0 / 12%);background: #fff;">
            <div class="contact__info--icon" style="height:5rem; width:5rem;line-height:5rem;">
              <img src="../assets/img/icon/finance.png">
            </div>
            <div class="contact__info--content">
              <div class="contact__info--content__title ">Register Get $10</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-6 custom-col mb-30">
          <div class="contact__info--items text-center" style="-webkit-box-shadow: 0px 1px 18px 0px rgb(0 0 0 / 12%);box-shadow: 0px 1px 18px 0px rgb(0 0 0 / 12%);background: #fff;">
            <div class="contact__info--icon" style="height:5rem; width:5rem;line-height:5rem;">
              <img src="../assets/img/icon/no-fee.png">
            </div>
            <div class="contact__info--content">
              <div class="contact__info--content__title ">No service fees</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactInfo"
}
</script>

<style scoped lang="scss">


@import '@/style/common.scss';

.row {
  margin-right: -.625rem;
  margin-left: -.625rem;
}
.row>* {
  padding-right: .625rem;
  padding-left: .625rem;
}
@media only screen and (max-width: 991px) {
  .contact__info--section{
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }
}
.contact__info--items {
  background: #f9f9f9;
  padding:1.25rem 0.9375rem;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.contact__info--items:hover {
  -webkit-transform: translatey(-10px);
  transform: translatey(-10px)
}

.contact__info--items:hover .contact__info--icon {
  background: #6610f2
}

.contact__info--icon {
  background: #0d6efd;
  color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin: 0 auto 0.625rem;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 50%
}

.contact__info--content__title {
  font-weight: bold;
  margin-bottom: 0.625rem;
  //font-weight: w;
}

.contact__info--content__desc {
  color: #646464;
  font-size: 0.875rem
}

@media only screen and (min-width: 1200px) {
  .contact__info--content__desc {
    font-size: 0.9375rem
  }
}
</style>
