<template>
  <section class="section transaction">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Latest Transactions</h4>
            <p class="text-muted para-desc mx-auto mb-0">
              Our goal is to simplify investing so that anyone can be an investor. Our members come from all over the
              world, they
              trade all the time, our platform is completely authentic.
            </p>
          </div>
        </div>
      </div>
      <el-row v-if="huadon.lastWithdrawList == undefined && huadon.lastDepositList==undefined"
              v-loading="huadon.lastWithdrawList == undefined && huadon.lastDepositList == undefined"
              element-loading-background="rgba(0, 0, 0, 0)">
      </el-row>
      <el-row v-else type="flex" style="flex-wrap: wrap;" justify="space-around">
        <el-col :xs="24" :span="10" class="m-2">
          <div>
            <div class="text-center m-5">
              <span style="border-bottom:2px solid #2f55d4;color: #2f55d4;font-weight: bold;">Last Deposit</span>
            </div>
            <article class="activity__product--area1">
              <vueSeamlessScroll :data="huadon.lastDepositList" :class-option="options" class="seamless-warp">
                <div>
                  <div class="activity__product--card1" v-for="(it,i) in huadon.lastDepositList" :key="i">
                    <div class="d-flex align-items-center justify-content-between" style="padding-top:2px;padding-bottom: 2px;" >
                      <div class="activity__product--card__left1 d-flex align-items-center">
                        <!--                    <div class="activity__product&#45;&#45;card__thumbnail1">-->
                        <!--                      <img src="/assets/flags/4x3/br.svg" style="width:60%;">-->
                        <!--                    </div>-->
                        <div class="activity__product--card__content">
                          <div class="activity__product--card__title" style="margin-bottom:0px;">{{it.email}}</div>
                          <div class="activity__product--card__subtitle" style="color:red; margin-bottom:0px;">{{ it.amount }} {{it.coinType}}</div>
                          <span class="activity__product--card__time">{{ it.updatedAt | parseTime('{d}/{m}/{y}') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!--              <div v-for="(it,i) in huadon.lastDepositList" :key="i">-->
                  <!--                <div class="card m-2 p-2"-->
                  <!--                     style="font-weight: bold;box-shadow:0 0 3px rgba(60, 72, 88, 0.15) !important;border: none;">-->
                  <!--                  <div>{{ it.email }}</div>-->
                  <!--                  <div style="color: red;">${{ it.amount * it.usdtExchangeRate }}</div>-->
                  <!--                  <div>{{ getDate(it.updatedAt) }}</div>-->
                  <!--                </div>-->
                  <!--              </div>-->
                </div>
              </vueSeamlessScroll>
            </article>

          </div>
        </el-col>
        <el-col :xs="24" :span="10" class="m-2">
          <div>
            <div class="text-center m-5">
              <span style="border-bottom:2px solid #2f55d4;color: #2f55d4;font-weight: bold;">Last Withdraw</span>
            </div>
            <article class="activity__product--area1">
              <vueSeamlessScroll :data="huadon.lastWithdrawList" :class-option="options" class="seamless-warp">
                <div>
                  <div class="activity__product--card1" v-for="(it,i) in huadon.lastWithdrawList" :key="i">
                    <div class="d-flex align-items-center justify-content-between" style="padding-top:2px;padding-bottom: 2px;" >
                      <div class="activity__product--card__left1 d-flex align-items-center">
                        <!--                    <div class="activity__product&#45;&#45;card__thumbnail1">-->
                        <!--                      <img src="/assets/flags/4x3/br.svg" style="width:60%;">-->
                        <!--                    </div>-->
                        <div class="activity__product--card__content">
                          <div class="activity__product--card__title" style="margin-bottom:0px;">{{it.email}}</div>
                          <div class="activity__product--card__subtitle" style="color:red; margin-bottom:0px;">{{ it.amount }} {{it.coinType}}</div>
                          <span class="activity__product--card__time">{{ it.updatedAt | parseTime('{d}/{m}/{y}') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!--              <div v-for="(it,i) in huadon.lastDepositList" :key="i">-->
                  <!--                <div class="card m-2 p-2"-->
                  <!--                     style="font-weight: bold;box-shadow:0 0 3px rgba(60, 72, 88, 0.15) !important;border: none;">-->
                  <!--                  <div>{{ it.email }}</div>-->
                  <!--                  <div style="color: red;">${{ it.amount * it.usdtExchangeRate }}</div>-->
                  <!--                  <div>{{ getDate(it.updatedAt) }}</div>-->
                  <!--                </div>-->
                  <!--              </div>-->
                </div>
              </vueSeamlessScroll>
            </article>

          </div>
        </el-col>
      </el-row>
    </div>

  </section>
</template>

<script>
import indexApi from "@/apis/indexApi";
import vueSeamlessScroll from 'vue-seamless-scroll'
import date, {parseTime} from "@/date";
// import '@/style/common.scss'
export default {
  name: "Transactions",
  components:{vueSeamlessScroll},
  filters: {parseTime},
  data() {
    return {
      options: {
        autoPlay: false,
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 88, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100000000000 // 单步运动停止的时间(默认值1000ms)
      },
      huadon: {
        lastDepositList: undefined,
        lastWithdrawList: undefined
      },
    }
  },
  created() {
    indexApi.liushui(res => {
      this.huadon = res.data
      if(this.huadon && (this.huadon.lastDepositList.length > 0 || this.huadon.lastDepositList.length > 0)){
        this.$nextTick(() => {
          let height = document.getElementsByClassName('activity__product--card1')[0].clientHeight;
          this.options = {
            autoPlay: true,
            step: 1, // 数值越大速度滚动越快
            limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
            hoverStop: true, // 是否开启鼠标悬停stop
            direction: 1, // 0向下 1向上 2向左 3向右
            openWatch: true, // 开启数据实时监控刷新dom
            singleHeight: height, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
            waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
          }
        })
      }

    })
  },
  methods:{
    getDate(d) {
      return date.getDate(d)
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/style/common.scss';
.seamless-warp{
  height: 100%;
  overflow: hidden;
}
//.huadonlist {
//  padding: 15px;
//  @media (min-width: 768px) {
//    padding: 50px 160px;
//  }
//}
@media only screen and (max-width: 991px) {
  .transaction {
    font-size: 0.875rem;
    line-height: 1.5rem
  }
}

.activity__product--card {
  //var(--border-color)
  border: 1px solid #e4e4e4;
  padding: 0.9375rem;
  border-radius: 10px
}


.activity__product--card1 {
  //var(--border-color)
  border-bottom: 1px solid #e4e4e4;
  padding: 0.625rem
}
@media only screen and (min-width: 992px) {
  .activity__product--card {
    padding: 1.25rem
  }
//var(--border-color)
  .activity__product--card1 {
    border-bottom: 1px solid #e4e4e4;
    padding: 0.625rem
  }
}


.activity__product--card__link {
  gap: 20px
}

@media only screen and (max-width: 479px) {
  .activity__product--card__link {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}

.activity__product--card__left {
  gap: 1.25rem
}

@media only screen and (min-width: 992px) {
  .activity__product--card__left {
    gap: 1.5625rem
  }
}

@media only screen and (min-width: 1200px) {
  .activity__product--card__left {
    gap: 1.875rem
  }
}

@media only screen and (max-width: 399px) {
  .activity__product--card__left {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}

.activity__product--card__left1 {
  gap: 1.25rem
}

@media only screen and (min-width: 992px) {
  .activity__product--card__left1 {
    gap: 1.5625rem
  }
}

@media only screen and (min-width: 1200px) {
  .activity__product--card__left1 {
    gap: 1.875rem
  }
}

.activity__product--card__thumbnail {
  width: 100%
}

.activity__product--card__thumbnail1 {
  width: 100%
}

@media only screen and (min-width: 300px) {
  .activity__product--card__thumbnail1 {
    width: 120px
  }
}

@media only screen and (min-width: 400px) {
  .activity__product--card__thumbnail {
    width: 120px
  }

  .activity__product--card__thumbnail1 {
    width: 100px
  }
}

@media only screen and (min-width: 576px) {
  .activity__product--card__thumbnail {
    width: 125px
  }

  .activity__product--card__thumbnail1 {
    width: 100px
  }
}

@media only screen and (min-width: 992px) {
  .activity__product--card__thumbnail {
    width: 135px
  }

  .activity__product--card__thumbnail1 {
    width: 100px
  }
}

@media only screen and (min-width: 1200px) {
  .activity__product--card__thumbnail {
    width: 150px
  }

  .activity__product--card__thumbnail {
    width: 100px
  }
}

@media only screen and (min-width: 1200px) {
  .transactions-warper {
    padding-left: 200px;
    padding-right: 200px
  }
}

@media only screen and (max-width: 399px) {
  .activity__product--card__thumbnail img {
    width: 100%
  }
}

.activity__product--card__title {
  line-height: 1.5rem;
  font-size: 1.0625rem;
  margin-bottom:  0.5rem;
  font-weight: 500;
}

@media only screen and (min-width: 992px) {
  .activity__product--card__title {
    font-size: 1.125rem
  }
}

@media only screen and (min-width: 1200px) {
  .activity__product--card__title {
    font-size: 1.25rem;
    margin-bottom: 0.625rem
  }
}

.activity__product--card__subtitle {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  color: #646464;
  font-weight: 500
}

@media only screen and (min-width: 576px) {
  .activity__product--card__subtitle {
    font-size: 0.9375rem
  }
}

@media only screen and (min-width: 1200px) {
  .activity__product--card__subtitle {
    font-size: 1rem;
    margin-bottom: 0.625rem
  }
}

.activity__product--card__subtitle span {
  color: #0d6efd
}

.activity__product--card__time {
  font-weight: 500;
  color: #000
}

@media only screen and (max-width: 479px) {
  .activity__product--card__time {
    font-size: 0.875rem
  }
}

.activity__product--card__icon {
  width: 2.8125rem;
  height: 2.8125rem;
  line-height: 2.8125rem;
  background: #f9f9f9;
  text-align: center;
  border-radius: 50%;
  font-weight: 700;
  color: #000
}

.activity__product--card__icon:hover {
  background: #0d6efd;
  color: #fff
}

.activity__product--area {
  border: 2px solid #6610f2;
  padding: 0.9375rem
}

.activity__product--area1 {
  overflow: hidden;
  height: 540px;
  padding: 0.9375rem;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
  -webkit-box-shadow: 0 0 30px 10px rgba(96, 133, 237, .05);
  box-shadow: 0 0 30px 10px rgba(96, 133, 237, .05)
}

@media only screen and (min-width: 313px) {
  .activity__product--area1 {
    overflow: hidden;
    height: 450px
  }
}

@media only screen and (min-width: 535px) {
  .activity__product--area1 {
    overflow: hidden;
    height: 500px
  }
}

@media only screen and (min-width: 1200px) {
  .activity__product--area1 {
    overflow: hidden;
    height: 540px
  }
}
</style>
