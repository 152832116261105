<script>
import {Carousel, Slide} from "vue-carousel";

export default {
  name: 'Testimonial1',
  props: {
    testimonialData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      slide: 0
    };
  },
  components: {
    Carousel,
    Slide,
  },
  methods:{
    onSlideStart (){

    },
    onSlideEnd(){

    },
    prev() {
      this.$refs.myCarousel.prev()
    },
    next() {
      this.$refs.myCarousel.next()
    }
  }
};
</script>


<template>
  <div class="Testimonial1">
    <div class="testimonialSwiper-warper">
      <b-carousel
          ref="myCarousel"
          id="testimonialSwiper"
          v-model="slide"
          :interval="5000"
          style="background: #fff;    border-radius: 30px 30px 30px 30px; -webkit-box-shadow: 0px 0px 30px 10px rgba(96, 133, 237, 0.05); box-shadow: 0px 0px 30px 10px rgba(96, 133, 237, 0.05);"

      >
        <!-- Text slides with image -->

        <b-carousel-slide v-for="(item, index) in testimonialData" :key="item.id">
          <div slot="img">
            <div class="testimonial__item" style="height:360px;">
              <h5 class="mb-10">{{item.title}}</h5>
              <p class="primary cta__space" v-html="item.message">

              </p>
              <div class="testimonial__author">
                <div class="testimonial__author__info">
                  <img :src="item.profile">
                  <div>
                    <p class="content__space--extra--small">{{item.name}}</p>
                    <p></p>
                  </div>
                </div>
                <div class="testimonial__author__ratings">
                  <el-rate size="20" :value="item.rate" disabled></el-rate>

<!--                  <i class="fa fa-star"></i>-->
<!--                  <i class="fa fa-star"></i>-->
<!--                  <i class="fa fa-star"></i>-->
<!--                  <i class="fa fa-star"></i>-->
<!--                  <i class="fa fa-star"></i>-->
                </div>
              </div>
            </div>
          </div>
        </b-carousel-slide>

        <button class="carousel-control-prev" type="button" @click="prev" data-target="#testimonialSwiper" data-slide="prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-chevron-left" style=" background-color: blue; border-radius: 30px;">
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </button>
        <button class="carousel-control-next" type="button" @click="next" data-target="#testimonialSwiper" data-slide="next">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-chevron-right" style=" background-color: blue; border-radius: 30px;">
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </button>
      </b-carousel>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/style/common.scss';
//@import url('../assets/font/font-awesome.min.css');
.Testimonial1 {
  //background-color: transparent;
}

button {
  background-color: transparent;
  border: none;
}
//.fa{
//  display: inline-block;
//  font: normal normal normal 14px/1 FontAwesome;
//  font-size: inherit;
//  text-rendering: auto;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
//.fa-star:before {
//  content: "\f005";
//}
@media only screen and (min-width: 992px) {
  .testimonialSwiper-warper {
    padding-left: 7.5rem;
    padding-right: 7.5rem
  }
}

@media only screen and (min-width: 1200px) {
  .testimonialSwiper-warper {
    padding-left: 11.25rem;
    padding-right: 11.25rem
  }
}

@media only screen and (min-width: 1366px) {
  .testimonialSwiper-warper {
    padding-left: 16.25rem;
    padding-right: 16.25rem
  }
}

.testimonial-swiper {
  width: 70%;
  height: 100%
}

.testimonial-swiper-slide {
  text-align: center;
  font-size: 18px;
  background: rgb(0 0 0/0%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center
}

.testimonial-swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover
}

.testimonial__item {
  z-index: 1;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 60px 80px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center
}

@media only screen and (max-width: 767px) {
  .testimonial__item {
    max-width: 480px;
    padding: 60px 30px
  }
}

@media only screen and (max-width: 575px) {
  .testimonial__item {
    max-width: 380px;
    padding: 60px 20px
  }
}

.testimonial__item h5 {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  font-style: normal;
  margin-top: -5px;
  color: #0d6efd
}

.testimonial__item p {
  color: #6c757d;
  text-align: left
}

.testimonial__item .testimonial__author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

@media only screen and (max-width: 575px) {
  .testimonial__item .testimonial__author {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

.testimonial__item .testimonial__author .testimonial__author__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 30px
}

@media only screen and (max-width: 575px) {
  .testimonial__item .testimonial__author .testimonial__author__info {
    gap: 20px
  }
}

.testimonial__item .testimonial__author .testimonial__author__info img {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%
}

.testimonial__item .testimonial__author .testimonial__author__info h5, .testimonial__item .testimonial__author .testimonial__author__info p {
  color: #6c757d
}

@media only screen and (max-width: 575px) {
  .testimonial__item .testimonial__author .testimonial__author__ratings {
    margin-left: 50px
  }
}

.testimonial__item .testimonial__author .testimonial__author__ratings  {
  ::v-deep {
    .el-rate__icon {
      font-size: 26px;
      color: #ffad02;
      margin: 0 -2px;
    }
  }

}

@media only screen and (max-width: 575px) {
  .testimonial__item .testimonial__author .testimonial__author__ratings {
    ::v-deep {
      .el-rate__icon {
        font-size: 24px;
        color: #ffad02
      }
    }
  }
}

.testimonial__item__wrapper .slick-slide {
  margin: 0 12px;
  text-align: center
}

.testimonial__item__wrapper .slick-list {
  margin-left: -12px;
  margin-right: -12px
}

.testimonial__item__wrapper .arrow--button {
  height: 40px;
  width: 90px;
  line-height: 40px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 10px;
  z-index: 1
}

@media only screen and (max-width: 1399px) {
  .testimonial__item__wrapper .arrow--button {
    width: 70px
  }
}

@media only screen and (max-width: 991px) {
  .testimonial__item__wrapper .arrow--button {
    display: none
  }
}

.testimonial__item__wrapper .arrow--button img {
  width: 21px;
  height: 15px
}

.testimonial__item__wrapper .arrow--button:hover {
  background-color: #7e77d2
}

.testimonial__item__wrapper .slick-prev {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media only screen and (max-width: 1399px) {
  .testimonial__item__wrapper .slick-prev {
    right: 0
  }
}

@media only screen and (max-width: 991px) {
  .testimonial__item__wrapper .slick-prev {
    display: none !important
  }
}

.testimonial__item__wrapper .slick-next {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media only screen and (max-width: 1399px) {
  .testimonial__item__wrapper .slick-next {
    left: 0
  }
}

@media only screen and (max-width: 991px) {
  .testimonial__item__wrapper .slick-next {
    display: none !important
  }
}

.testimonial__animation img {
  position: absolute;
  z-index: -1
}

.testimonial__animation .circle__sm {
  max-width: 7vw;
  height: auto;
  top: 18%;
  left: 18vw;
  animation: partner 10s infinite alternate-reverse
}

@media only screen and (max-width: 767px) {
  .testimonial__animation .circle__sm {
    max-width: 15vw
  }
}

.testimonial__animation .circle__sm--two {
  max-width: 7vw;
  bottom: 17%;
  right: 10vw;
  animation: ticket 15s infinite alternate-reverse;
  -webkit-animation-delay: 3s;
  animation-delay: 3s
}

@media only screen and (max-width: 991px) {
  .testimonial__animation .circle__sm--two {
    bottom: 4%
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__animation .circle__sm--two {
    max-width: 15vw
  }
}

.testimonial__animation .circle__lg {
  left: 3%;
  bottom: 12%;
  max-width: 19vw;
  animation: multiply 15s infinite alternate-reverse
}

@media only screen and (max-width: 767px) {
  .testimonial__animation .circle__lg {
    left: 1%;
    bottom: 4%;
    max-width: 40vw
  }
}

.testimonial__animation .circle__lg--two {
  right: 1%;
  top: 17%;
  max-width: 19vw;
  animation: multiply 15s infinite alternate-reverse;
  -webkit-animation-delay: 3s;
  animation-delay: 3s
}

@media only screen and (max-width: 767px) {
  .testimonial__animation .circle__lg--two {
    right: 1%;
    top: 4%;
    max-width: 40vw
  }
}

.testimonial-slider-wrapper {
  padding: 50px;
  padding-top: 70px;
  background: #fff;
  border-radius: 30px 30px 30px 30px;
  -webkit-box-shadow: 0 0 30px 10px rgba(96, 133, 237, .2);
  box-shadow: 0 0 30px 10px rgba(96, 133, 237, .2);
  position: relative;
  margin-top: 80px
}
</style>
